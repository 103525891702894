import React from "react"
import { graphql } from "gatsby"
// import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
// import { RiArrowRightSLine } from "react-icons/ri"

import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import SEO from "../components/seo"
import MailingListSignUp from "../components/mailing-list-sign-up"

// mods
import { InlineWidget } from "react-calendly";

export const pageQuery = graphql`
  query HomeQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 50, maxHeight: 50, quality: 80, srcSetBreakpoints: [960, 1440]) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""
  const ImageCategory = frontmatter.categoryImage ? frontmatter.categoryImage.childImageSharp.fluid : ""
  const ImageHome = frontmatter.homeImage ? frontmatter.homeImage.childImageSharp.fluid : ""

  // Parse route
  let qsCat, qsPrefix
  if (typeof window !== `undefined`) {
    const params = new URLSearchParams(document.location.search.substring(1))
    qsCat = (params.get("cat")) ? params.get("cat") : ""
    qsPrefix = (params.get("pre")) ? params.get("pre") : ""
  }

	return (
		<Layout>
      <SEO/>
      <div className="home-banner grids col-1 sm-2">
        <div>
          {Image ? (
            <Img 
              fluid={Image} 
              alt={frontmatter.title + ' - Featured image'}
              className="logo-image"
            />
          ) : ""}
          <h1 className="title">{frontmatter.title}</h1>
          <p className="tagline">{frontmatter.tagline}</p>
          <p className="tagline tagline-cat">{qsPrefix} {qsCat}</p>
          <div className="description" dangerouslySetInnerHTML={{__html: html}}/>
        </div>
        <div className="App">
          {/* <InlineWidget url="https://calendly.com/dornfeld-capital/30min" /> */}
        </div>
      </div>

      {/* <BlogListHome/> */}
      {/* <MailingListSignUp/> */}
      
		</Layout>
	)
}

export default HomePage
